.cancelBtn {
    margin-right:'60px'
}

@media only screen and (max-width: 400px) {
    .cancelBtn {margin-right:'50px'}
  }

  @media only screen and (min-width: 580px ) {
    .cancelBtn {margin-right:'60px'}
  }

  @media only screen and (min-width: 700px) {
    .cancelBtn {margin-right:'60px'}
  }

  @media only screen and (max-width: 1200px) {
    .cancelBtn {margin-right:'0px'}
  }