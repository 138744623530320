.photo-list {
  width: 90%;
  list-style: none;
  margin: 20px auto;
}

.ui.segment.reminderList.visible {
  left: 60%;
  position: absolute;
  top: 12%;
  z-Index: 100;
  box-shadow: 0 2px 8px grey;

}

.photo-item {
  display: flex;
  width: 300px;
  margin: 20px 0;
}

.photo-item img {
  display: flex;
  width: 100%;
  border: 4px double rgba(0, 0, 0, .12);
}

@media only screen and (max-width: 600px) {
  .photo-list {width:85%}
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .photo-list {width:75%}
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .photo-list {width:90%}
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .photo-list {width:65%}
} 
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .photo-list {width:75%}
}

@media (max-width:425px){
  .ui.segment.reminderList.visible {
    top: 19%;
    transition: unset;
    left: 0;
    width: 100%;
  }    
  }


/* .masonary {
  @media only screen and (min-width: 300px, max-width: 420px){
    columns {2},
    spacing {1}
  }
  @media only screen and (min-width:480px, max-width: 750px){
    columns {3},
    spacing {1}
  }
  @media only screen and (min-width: 750px, max-width: 1200px){
    columns {4},
    spacing {1}
  }
} */
